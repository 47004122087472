import request from '@/utils/request'
export function listWarning (data) {
  return request({
    url: '/Warning/listWarning',
    method: 'POST',
    data
  })
}
export function saveWarning (data) {
  return request({
    url: '/Warning/SaveWarning',
    method: 'POST',
    data
  })
}
export function ByIdSelcectWarning (data) {
  return request({
    url: '/Warning/ByIdSelcectWarning',
    method: 'POST',
    data
  })
}
export function updateWarning (data) {
  return request({
    url: '/Warning/updateWarning',
    method: 'POST',
    data
  })
}
export function stateSwitchWarning (data) {
  return request({
    url: '/Warning/stateSwitchWarning',
    method: 'POST',
    data
  })
}
export function deleteWarning (data) {
  return request({
    url: '/Warning/deleteWarning',
    method: 'POST',
    data
  })
}
export function listEmailUsers (data) {
  return request({
    url: '/Warning/listEmailReceive',
    method: 'POST',
    data
  })
}
export function listWXUsers (data) {
  return request({
    url: '/Warning/listWXReceive',
    method: 'POST',
    data
  })
}
export function selectAllMonitorSubject (data) {
  return request({
    url: '/Warning/selectAllMonitorSubject',
    method: 'POST',
    data
  })
}
export function setToWarningMessage (data) {
  return request({
    url: '/data/createWarningMessage',
    method: 'POST',
    data
  })
}
export function getMatchWarningData (data) {
  return request({
    url: '/data/getMatchWarningData',
    method: 'POST',
    data
  })
}
