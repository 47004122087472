<template>
  <section class="form-warning-wrap" v-loading="bodyLoading">
    <h2 class="title">设置通知内容、标题和发送频率</h2>
    <el-form ref="form" :model="form" label-width="80px" :rules="rules" v-loading="loading">
      <el-form-item label="选择分类" required="">
        <el-button  v-loading="msLoading" size="medium" @click="showSelMs">点击选择分类</el-button>
        <div style="margin: 10px" v-if="selMS">
          <el-tree :data="data" @check-change="modifyIds" show-checkbox default-expand-all node-key="id" ref="tree">
                  <span class="custom-tree-node" slot-scope="{node, data}">
                    <span>{{ data.title }}</span>
                  </span>
          </el-tree>
        </div>
      </el-form-item>
      <el-form-item label="预警名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="预警级别">
        <el-select v-model="form.level">
          <el-option label="一级" value=1></el-option>
          <el-option label="二级" value=2></el-option>
          <el-option label="三级" value=3></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="特级分级">
        <el-slider style="width: 425px" range v-model="form.specialLevelArray" :marks="marks" @change="changeSlider"></el-slider>
        <div>
          <span style="color: #909399; position: relative; left: 410px; top: -10px">
            普通
          </span>
        </div>
        <div>
          <span style="color: red">
            通过数据情感分数判定，值越接近0则代表数据情感负面倾向越高
          </span>
        </div>
      </el-form-item>
      <el-form-item label="接收时间" class="time-wrap required">
        <el-form-item label="" style="display: inline-block;" prop="startTime">
          <el-time-select
            @input="selectChange"
            v-model="form.startTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00'
            }"
            placeholder="开始时间">
          </el-time-select>
        </el-form-item>
        <span class="line">-</span>
        <el-form-item label="" style="display: inline-block;"  prop="endTime">
          <el-time-select
            @input="selectChange"
            v-model="form.endTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00'
            }"
            placeholder="结束时间">
          </el-time-select>
        </el-form-item>
      </el-form-item>
      <el-form-item label="预警间隔">
        <el-select v-model="form.interval">
          <el-option label="5分钟" value=0></el-option>
          <el-option label="15分钟" value=1></el-option>
          <el-option label="30分钟" value=2></el-option>
          <el-option label="1小时" value=3></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预警次数" v-show="false">
        <el-select v-model="form.count">
          <el-option label="1" value="1"></el-option>
          <el-option label="2" value="2"></el-option>
          <el-option label="3" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="周末预警">
        <el-radio-group v-model="form.weekendWarning">
          <el-radio label="1">周末预警</el-radio>
          <el-radio label="0">周末不预警</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="预警规则">
        <el-select v-model="form.type">
          <el-option label="简易" value="0"></el-option>
          <el-option label="自定义" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="核心词">
        <textarea style="width: 425px;height: 100px" v-model="form.coreWord" placeholder="多词以顿号 ( 、) 分割"></textarea>
        <div>
          <span style="color: red">
            如不设置核心词则系统会自动将所选分类下的所有负面数据判定为预警数据
          </span>
        </div>
      </el-form-item>
      <el-form-item  v-if="form.type === '1'"  label="同现词" align="center">
        <textarea style="width: 425px;height: 100px;float: left" v-model="form.cooccurrenceWord" placeholder="多词以顿号 ( 、) 分割"></textarea>
      </el-form-item>
      <el-form-item  v-if="form.type === '1'"  label="排除词" align="center">
        <textarea style="width: 425px;height: 100px;float: left" v-model="form.excludeWord" placeholder="多词以顿号 ( 、) 分割"></textarea>
      </el-form-item>
      <el-form-item label="预警来源">
        <el-checkbox-group @change="checboxChange" v-model="industryArray">
          <el-checkbox v-for="item in industryList" :label="item.id" :name="item.name" :key="item.id">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="数据匹配">
        <el-button @click="startMatch" type="success" size="small">匹配24小时内的数据</el-button>
      </el-form-item>
      <h2 class="title">通知接收人</h2>
      <el-form-item label="O端微信">
        <el-switch
          v-model="form.oWechat"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item label="预警方式">
        <el-checkbox-group v-model="form.warningWay" @change="changeWarningWay">
          <el-checkbox label="0" value="0">邮箱</el-checkbox>
          <el-checkbox label="1" value="1">微信</el-checkbox>
          <el-checkbox label="2" value="2">APP</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="form.warningWay.indexOf('0') !== -1" label="邮箱">
        <template>
          <el-select v-model="form.selectedEmail" @change="changeEmail" multiple filterable placeholder="请选择">
            <el-option
              v-for="item in emailList"
              :key="item.label"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-form-item>
      <el-form-item  v-if="form.warningWay.indexOf('0') !== -1 && diyEmailStatus === 1" label="自定义">
        <el-input  @input="changeWX" v-model="form.diyEmailList" placeholder="多邮箱以顿号 ( 、) 分割"></el-input>
      </el-form-item>
      <el-form-item v-if="form.warningWay.indexOf('1') !== -1" label="微信">
        <template>
          <el-select v-model="form.selectedWX" @change="changeWX" multiple filterable placeholder="请选择">
            <el-option
              v-for="item in wxList"
              :key="item.label"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-form-item>
      <el-form-item v-if="form.warningWay.indexOf('2') !== -1" label="APP">
        <template>
          <el-select v-model="form.selectedApp" @change="changeAPP" multiple filterable placeholder="请选择">
            <el-option
              v-for="item in wxList"
              :key="item.label"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">完成</el-button>
        <el-button size="medium" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="matchDialogShow" title="命中数据" width="800px" top="2vh">
      <div v-loading="matchDialogLoading">
        <div style="text-align: center; margin-bottom: 20px">匹配到了<span style="color: #2d64b3; font-weight: bold; margin: 0 5px">{{ matchDialogCount }}</span>条数据，下方按时间倒序显示TOP50</div>
        <div>
          <div v-for="data in matchDialogDataArray" style="margin-bottom: 10px; line-height: 22px">
            <div style="font-size: 16px; color: #2d64b3; font-weight: bold">
              <span v-html="data.title"></span>
            </div>
            <div style="font-size: 14px; margin-top: 5px;word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;overflow: hidden;">
              <span v-html="data.body"></span>
            </div>
            <div style="text-align: right; margin-top: 5px">
              <span>{{ data.publishDate.substring(0, 19) }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { saveWarning, ByIdSelcectWarning, updateWarning, listEmailUsers, listWXUsers, selectAllMonitorSubject, getMatchWarningData } from '@/api/settings/warning'
import eventHub from '@/utils/eventHub'
export default {
  data () {
    return {
      tags: [],
      selMS: false,
      msLoading: true,
      bodyLoading: false,
      receiveParam: '',
      diyEmailStatus: 0,
      emailList: [],
      wxList: [],
      industryList: [
        {
          id: 1,
          name: '网媒'
        },
        {
          id: 2,
          name: '贴吧'
        },
        {
          id: 3,
          name: '微信'
        },
        {
          id: 4,
          name: '博客'
        },
        {
          id: 5,
          name: '论坛'
        },
        {
          id: 6,
          name: '电子报'
        },
        {
          id: 7,
          name: '微博'
        },
        {
          id: 8,
          name: '其它'
        },
        {
          id: 9,
          name: '问答'
        },
        {
          id: 10,
          name: '客户端'
        },
        {
          id: 11,
          name: '视频'
        }
      ],
      industryArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      form: {
        id: '',
        name: '',
        level: '2',
        startTime: '',
        endTime: '',
        interval: '0',
        count: '3',
        weekendWarning: '1',
        type: '0',
        warningWay: '0',
        receive: '',
        coreWord: '',
        cooccurrenceWord: '',
        excludeWord: '',
        receiptTime: '',
        selectedEmail: [],
        selectedWX: [],
        selectedApp: [],
        diyEmailList: '',
        sortId: '',
        receiveId: '',
        specialLevelArray: [30, 60]
      },
      type: '',
      loading: false,
      data: [],
      selectOrg: {
        orgsid: []
      },
      rules: {
         name: [{ required: true, message: '请输入预警名称', trigger: 'blur' }],
         startTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
         endTime: [{ required: true, message: '请选择结束时间', trigger: 'change' }]
      },
      marks: {},
      matchDialogShow: false,
      matchDialogLoading: false,
      matchDialogDataArray: [],
      matchDialogCount: 0
    }
  },
  methods: {
    checboxChange () {
      this.form.industryId = this.industryArray.join('、')
      this.$forceUpdate()
    },
    modifyIds(data,checked, node){
      var selected = this.$refs.tree.getCheckedNodes()
      this.tags = []
      selected.forEach((item,index)=>{
        this.tags.push(item.id)
      })
    },
    showSelMs () {
      this.selMS = true
      if(this.form.warningSortId){
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.form.warningSortIds);
        })
      }
    },
    changeWX () {
      this.$forceUpdate()
    },
    changeAPP () {
      this.$forceUpdate()
    },
    changeWarningWay () {
      this.$forceUpdate()
    },
    changeEmail (selVal) {
      this.$forceUpdate()
      if (selVal.indexOf(-1) !== -1) {
        this.diyEmailStatus = 1
      } else {
        this.diyEmailStatus = 0
        this.form.diyEmailList = ''
      }
    },
    changeSlider (val) {
      console.log(val)
      this.marks = {}
      this.marks[val[0]] = '特级'
      this.marks[val[1]] = '中等'
    },
    selectChange () {
      this.$forceUpdate()
    },
    async listAllEmail () {
      const res = await listEmailUsers()
      this.emailList = res.data
    },
    async listAllWX () {
      const res = await listWXUsers()
      this.wxList = res.data
    },
    async startMatch () {
      this.matchDialogCount = 0
      this.matchDialogDataArray = []
      this.matchDialogShow = true
      this.matchDialogLoading = true
      const res = await getMatchWarningData({
        nodeIdArray: this.tags,
        keywordString: this.form.coreWord
      })
      if (res.code === 0) {
        this.matchDialogDataArray = res.data.list
        this.matchDialogCount = res.data.count
      }
      this.matchDialogLoading = false
    },
    async onSubmit () {
      if(this.tags.length === 0){
        this.$message.error('请至少选择一个分类！')
        return false
      }
      this.form.sortId = this.tags
      if (this.form.oWechat === 1) {
        if (this.form.warningWay.length === 0 || (this.form.warningWay[0] !== '1' && this.form.warningWay[0] !== '1')) {
          this.$message.error('O端开启预警时，预警方式中的微信选项必须勾选')
          return false
        }
      }
      this.$refs['form'].validate(async (valid) => {
        if (this.form.warningWay.indexOf('0') !== -1) {
          this.receiveParamEmail = ''
          for (var i = 0; i < this.form.selectedEmail.length; i++) {
            if (this.form.selectedEmail[i] !== -1) {
              if (this.diyEmailStatus === 1) {
                this.receiveParamEmail += this.form.selectedEmail[i] + '、'
              } else {
                if (i < this.form.selectedEmail.length - 1) {
                  this.receiveParamEmail += this.form.selectedEmail[i] + '、'
                } else {
                  this.receiveParamEmail += this.form.selectedEmail[i]
                }
              }
            }
          }
          this.receiveParamEmail += this.form.diyEmailList
          this.form.receiveEmail = this.receiveParamEmail
        } else {
          this.form.receiveEmail = ''
        }
        if (this.form.warningWay.indexOf('1') !== -1) {
          this.receiveParamWechat = ''
          for (var j = 0; j < this.form.selectedWX.length; j++) {
            if (j < this.form.selectedWX.length - 1) {
              this.receiveParamWechat += this.form.selectedWX[j] + '、'
            } else {
              this.receiveParamWechat += this.form.selectedWX[j]
            }
          }
          this.form.sortId = this.tags
          this.form.receiveWechat = this.receiveParamWechat
        } else {
          this.form.receiveWechat = ''
        }
        if (this.form.warningWay.indexOf('2') !== -1) {
          this.receiveParamApp = ''
          for (var z = 0; z < this.form.selectedApp.length; z++) {
            if (z < this.form.selectedApp.length - 1) {
              this.receiveParamApp += this.form.selectedApp[z] + '、'
            } else {
              this.receiveParamApp += this.form.selectedApp[z]
            }
          }
          this.form.sortId = this.tags
          this.form.receiveApp = this.receiveParamApp
        } else {
          this.form.receiveApp = ''
        }
        if (valid) {
          this.form.receiptTime = this.form.startTime + '-' + this.form.endTime
          if (this.type === 'edit') {
            if (this.form.type === '0') {
              this.form.cooccurrenceWord = null
              this.form.excludeWord = null
            }
            this.form.id = this.$route.params.id
            this.form.multWarningWay = this.form.warningWay.join(',')
            this.form.warningWay = ''
            this.form.specialLevel = this.form.specialLevelArray.join(',')
            const res = await updateWarning(this.form)
            if (res.code === 0) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              this.$router.push('/settings/warning')
            } else {
              this.$message.error(res.msg)
            }
          } else {
            this.form.industryId = this.industryArray.join('、')
            this.form.multWarningWay = this.form.warningWay.join(',')
            this.form.warningWay = ''
            this.form.specialLevel = this.form.specialLevelArray.join(',')
            const res = await saveWarning(this.form)
            if (res.code === 0) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              this.$router.push('/settings/warning')
            } else {
              this.$message.error(res.msg)
            }
          }
        } else {
          console.log('error submit!!')
          return false;
        }
      })
    },
    async allMS () {
      this.msLoading = true
      const res = await selectAllMonitorSubject(this.form)
      this.data = res.data
      if (res.code === 0) {
        this.msLoading = false
      }
    },
    async getWarningById () {
      this.allMS()
      this.bodyLoading = true
      try {
        if (this.type === 'edit') {
          const res = await ByIdSelcectWarning({ id: this.$route.params.id })
          if (res.data[0].specialLevel) {
            res.data[0].specialLevelArray = res.data[0].specialLevel.split(',')
          } else {
            res.data[0].specialLevelArray = [30, 60]
          }
          this.changeSlider(res.data[0].specialLevelArray)
          this.form = res.data[0]
          this.form.level += ''
          this.form.interval += ''
          this.form.type += ''
          this.form.weekendWarning += ''
          this.form.warningWay += ''
          this.industryArray = this.form.industryId.split('、')
          for (var i = 0; i < this.industryArray.length; i++) {
            this.industryArray[i] = parseInt(this.industryArray[i])
          }
          if (!this.form.multWarningWay) {
            this.form.warningWay = []
          } else {
            this.form.warningWay = this.form.multWarningWay.split(',')
          }
          console.log(this.form.warningWay)
          var times = this.form.receiptTime.split('-')
          this.form.startTime = times[0]
          this.form.endTime = times[1]
          if (this.form.warningWay.indexOf('1') !== -1) {
            this.form.selectedWX = res.data[0].receiveWechat ? res.data[0].receiveWechat.split('、') : []
          }
          if (this.form.warningWay.indexOf('2') !== -1) {
            this.form.selectedApp = res.data[0].receiveApp ? res.data[0].receiveApp.split('、') : []
          }
          if (this.form.warningWay.indexOf('0') !== -1) {
            var test = res.data[0].receiveEmail ? res.data[0].receiveEmail.split('、') : []
            var selectedEmailList = []
            var diyEmailList = []
            for (var i = 0; i < test.length; i++) {
              for (var j = 0; j < this.emailList.length; j++) {
                if (test[i] === this.emailList[j].value) {
                  selectedEmailList.push(test[i])
                  break
                } else {
                  if (j === this.emailList.length - 1) {
                    if (selectedEmailList.indexOf(-1) === -1) {
                      selectedEmailList.push(-1)
                    }
                    diyEmailList.push(test[i])
                    this.diyEmailStatus = 1
                  }
                }
              }
            }
            this.form.diyEmailList = ''
            for (var z = 0; z < diyEmailList.length; z++) {
              console.log(diyEmailList[z])
              this.form.diyEmailList = z !== diyEmailList.length - 1 ? this.form.diyEmailList + diyEmailList[z] + '、' : this.form.diyEmailList + diyEmailList[z]
            }
            this.form.selectedEmail = selectedEmailList
          }
          this.$forceUpdate()
          this.bodyLoading = false
        } else {
          this.bodyLoading = false
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    this.type = this.$route.meta.type
    this.allMS()
    this.listAllEmail()
    this.listAllWX()
    setTimeout(this.getWarningById, 100)
    // this.getWarningById()
    if (this.type === 'edit') {
      // this.selctOneUser(this.$route.params.id)
      eventHub.$emit('msg', '修改预警')
      var _this = this
      setTimeout(function () {
        _this.showSelMs()
      }, 500)
    } else if (this.type === 'add') {
      this.bodyLoading = false
      this.form.warningWay = []
      eventHub.$emit('msg', '创建预警')
    }
  }
}
</script>

<style lang="scss">
.form-warning-wrap {
  width: 100%;
  min-height: 100%;
  background: #fff;
  border-radius: 2px;
  box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  position: relative;
  .title {
    font-size: 16px;
    color: #303133;
  }
  .el-tree{
    padding: 10px;
    padding-right: 10px;
    background: #F7F6FB;
    border-radius: 18px;
  }
  .time-wrap {
    .el-date-editor {
      &.el-input {
        width: 200px;
      }
    }
  }
  .el-form-item {
    .line {
      margin: 0 10px;
      text-align: center;
    }
    .el-input {
      width: 425px;
    }
    textarea {
      width:619px;
      height:202px;
      border:1px solid #DCDFE6;
      border-radius:4px;
      resize:none
    }
  }
}
</style>
